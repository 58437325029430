import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"


export const query = graphql`
  {
    gallery: allFile(
      filter: {relativeDirectory: {eq: "gallery"}}
      sort: {fields: base}
      ){
        edges {
          node {
            id
            base
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
  }
`

const GalleryPage = ({ data }) => {
  console.log(data);
  return (
    <Layout page="galeria">
      <Seo title="Galeria" />
      <SmallHeroSection />
      <DecorativeSection />

      <section id="gallery" className="gallery">
        <div className="section-title">
          <h2><span>Galeria</span></h2>
          <p>Zobacz propozycje naszych posiłków - <strong>kolorowo, świeżo i smacznie!</strong></p>
        </div>
        <div className="container-fluid">

          <SimpleReactLightbox>
            <SRLWrapper>
              <div className="row no-gutters">

                {data.gallery.edges.map(({ node }) => (
                  <div className="col-lg-3 col-md-4" key={node.id}>
                    <div className="gallery-item">
                      <a href={node.publicURL}>
                        <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base}/>
                      </a>
                    </div>
                  </div>
                ))}
              </div>

            </SRLWrapper>
          </SimpleReactLightbox>

        </div>
      </section>
    </Layout>
  )
}

export default GalleryPage
